/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import './articleCard.css'
  
const ArticleCard = ({node}) => {
    var title = node.frontmatter.title || node.fields.slug
  return (
    <Link
    className="w-full mb-6 md:w-article/2 lg:w-article/3 xl:w-article/4"
    to={node.fields.slug}
  >
    <span className="visually-hidden">{title}</span>
    <article className="article-card transition-shadow shadow-md hover:shadow-xl duration-300 ease-in-out">
      <div className="article-card-img-wrapper">
        <Img fluid={node.frontmatter.featuredImage.childImageSharp.fluid} className={"article-card-img"} />
      </div>
        <section className="p-6">
        <h3 className=" mb-2">
            {title}
        </h3>
        <p><small>{node.frontmatter.date}</small></p>
        <div className="article-card-excerpt">
         <p
          dangerouslySetInnerHTML={{
            __html: node.frontmatter.description || node.excerpt,
          }}
        />
        </div>
      </section>
    </article>

    </Link>
  )
}

export default ArticleCard
